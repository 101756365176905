// browse component styles


.wrapper-div {
    height: 200px; }

.browse-heading > .dropdown > .nav-link.dropdown-toggle:after {
    top: 40%; }

.browse-heading {
    flex-flow: row; }

.inner-div {
    display: flex;
    flex: 1;
    width: $browse-inner-div-width;
    flex-flow: column;
    text-align: $browse-inner-div-text-align;
    height: $browse-inner-div-height;
    padding-bottom: $browse-inner-div-padding-bottom;
    padding-top: $browse-inner-div-padding-top;
    justify-content: $browse-inner-div-justify-content;
    align-items: $browse-inner-div-align-items;
    background-size: $browse-inner-div-background-size; }

.browse-inner-wrapper-div {
    border: $browse-inner-div-border;
    border-radius: 5px; }

.browse-badge {
    border-top-right-radius: 5px; }

.inner-div > h5 {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical; }

.inner-div:hover > h5 {
    font-weight: $browse-inner-div-heading-hover-font-weight; }

.browse-item:hover {
    text-decoration: none; }

.default-investigator-icon > h1 {
    color: $white;
    font-weight: bolder;
    font-size: 2rem;
    font-family: 'Bubbler One'; }
#browse {
    .browse-nav-link {
        display: flex;
        background: transparent;
        font-weight: 500;
        box-shadow: none;
        font-size: $font-size-lg;
        justify-content: center;
        align-items: center;
        height: 3rem;
        border-radius: $border-radius-sm;
        color: $h1-color;
        position: relative;
        z-index: 100;
        &:hover {
            background: rgba($gray-900,0.1); }
        &.active {
            background: $white;
            border-radius: $border-radius-sm; } } }

.browse-slider {
    display:    inline-block;
    width:  100%;
    height: 2.625rem;
    border-radius:  $border-radius-sm;
    background-color:  $white;
    position:   absolute;
    z-index:    99;
    bottom: 0;
    transition: all .4s linear; }


.browse-inner-wrapper-div:hover, .browse-inner-wrapper-div-shadow:hover {
    border-radius: 8px;
    text-decoration: none;
    box-shadow: 0px 10px 20px $gray-200;
    transition: 1s box-shadow; }

.browse-item-img {
    height: $browse-item-img-height;
    width: $browse-item-img-width;
    margin: $browse-item-img-margin;
    object-fit: $browse-item-img-object-fit;
    svg {
        path {
            stroke: $gray-900; } } }

.browse-inner-wrapper-div:hover > .inner-div > .default-investigator-icon {
            border-width: 2px; }

.browse-tab, .browse-dropdown {
    background: rgba($gray-900,0.02);
    padding-top: $component-padding-y;
    padding-bottom: $component-padding-y; }

.browse-department-heading {
    color: $browse-department-heading-color; }

.browse-investigator-heading {
    color: $browse-investigator-heading-color; }
#browse {
    .dropdown-menu, .dropdown-item {
        color: $browse-dropdown-color;
        font-weight: $browse-dropdown-font-weight;
        background: $browse-dropdown-background;
        width: 100%;
        border-radius: $browse-dropdown-border-radius; }
    .dropdown-item:hover {
            background: $dropdown-link-hover-bg; }
    .dropdown-item.active {
            background: $dropdown-link-active-bg; } }
#browse {
    .dropdown-menu.show {
        padding: $browse-dropdown-menu-show-padding; } }

// ********** mobile css for browse component ********
#browse {
    .tab-section {
        padding: 0.313rem;
        border-radius: $border-radius;
        background-color: rgba(37, 45, 82, 0.1); } }

@media only screen and (max-width: 770px) {

    .wrapper-div {
        height: 80px; }

    .browse-heading > .dropdown > .nav-link.dropdown-toggle:after {
        top: 55%; }

    .inner-div {
        justify-content: $browse-inner-div-justify-content-mobile;
        height: $browse-inner-div-height-mobile;
        flex-flow: row;
        text-align: $browse-inner-div-text-align-mobile;
        border-radius: $browse-inner-div-border-radius-mobile;
        padding-left: $browse-inner-div-padding-left-mobile; }

    .browse-item-img {
        height: $browse-item-img-height-mobile;
        width: $browse-item-img-width-mobile; }

    .browse-heading {
        flex-flow: $browse-heading-flex-flow-mobile; }

    .browse-nav-link {
        font-size: $font-size-xs; }
    .tab-section {
        width: 100%; } }

// ****************** ends mobile css for browse component *************


// browse component styles ends here
