
$primary: #580f8b;
$dine-in-footer-bg: #333333;
$dine-in-footer-color: #D6D6D6;
$footer-link-color: #D6D6D6;
$footer-link-decoration: none;
$footer-link-color-hover: #D6D6D6;
$nav-item-active-bg: transparent;
$navbar-light-active-color: #580f8b;
$header-position: sticky;
$enable-navbar-animation: True;
$nav-active-underline-width: 3px;
$navbar-nav-link-font-size: 1rem;
$width-container-2: 1140px;
$hero-heading: #fff;

