// hero component styles

.hero-content-width {
    max-width: $hero-content-width; }

// *********** card/ no card width and height adjustment *************
.hero-card-height {
    height: $hero-card-height; }

.hero-card-width {
    width: $hero-card-height; }

.hero-box {
    width: $hero-box-width;
    height: $hero-box-height; }

.hide-block {
    opacity: 0; }

// *********** ends     card/ no card width and height adjustment *************

// *********** hero component alignments styles **************
.hero-content-alignment {
    display: flex;
    justify-content: $hero-content-alignment;
    align-items: center;
    height: $hero-component-height; }

.learn-more-button-align {
    justify-content: $hero-content-button-align; }
// *********** hero component alignment ends here ***********
.button-learn-more {
    background: #fff;
    color: $gray-900; }
.hero-card-margin {
    margin: $hero-card-margin-top $hero-card-margin-right $hero-card-margin-bottom $hero-card-margin-left; }

// *********** hero component widget styles **************

.button-learn-more, .button-learn-more:hover {
    border-radius: $btn-learn-more-border-radius;
    font-size: $learn-more-button-font-size; }

.hero-component {
    text-align: $hero-content-align;
    height: $hero-component-height;
    overflow: $hero-card-overflow;
    background: $hero-component-default-background; }

.hero-image {
    width: 100%;
    object-fit: cover; }

.hero-heading {
    color: $hero-heading;
    font-size: $hero-heading-font-size;
    padding: $hero-heading-padding-top $hero-heading-padding-right $hero-heading-padding-bottom $hero-heading-padding-left;
    line-height: 120%; }

.hero-description {
    color: $hero-description;
    font-size: $hero-description-font-size;
    padding: $hero-description-padding-top $hero-description-padding-right $hero-description-padding-bottom $hero-description-padding-left;
    overflow: hidden; }

.hero-card {
    background: $hero-card-background;
    padding: $hero-card-padding-top $hero-card-padding-right $hero-card-padding-bottom $hero-card-padding-left;
    height: $hero-card-height;
    width: $hero-card-width;
    display: flex;
    justify-content: center;
    flex-flow: column; }

.hero-container {
    background-size: $hero-background-size; }

// *********** ends hero component widget styles **************

// ********** mobile css for hero component ********

@media only screen and (max-width: 750px) {
    .hero-component {
        text-align: $hero-content-align-mobile;
        height: $hero-component-mobile-height; }

    .hero-card {
        height: $hero-card-height-mobile;
        width: $hero-card-width-mobile; }


    .hero-description {
        font-size: $hero-description-font-size-mobile;
        color: $hero-card-color-sm;
        width: auto; }

    .hero-heading {
        font-size: $hero-heading-font-size-mobile;
        color: $hero-card-color-sm; }

    .button-learn-more, .button-learn-more:hover {
        font-size: $learn-more-button-font-size-mobile; }



    .testimonial-block-test {
                flex-direction: column-reverse; }

    #find-study-hero {
        .divider {
            display: none; }
        .btn-block {
            max-width: 100%;
            width: 100%; }
        .custom-input {
             border: $input-border-width solid $input-border-color; } }

    // ********** ends mobile css for hero component ********


 }    // ********** tablet css for hero component ********


@media only screen and (max-width: 1200px) and (min-width: 768px) {
    .hero-component {
        text-align: $hero-content-align-tablet;
        height: $hero-component-tablet-height;
        background-position: bottom; }

    .hero-description {
        font-size: $hero-description-font-size-tablet; }

    .hero-card {
        height: $hero-card-height-tablet;
        width: $hero-card-width-tablet; }

    .hero-heading {
        font-size: $hero-heading-font-size-tablet; }

    .button-learn-more, .button-learn-more:hover {
        font-size: $learn-more-button-font-size-tablet; }

 }    // ********** ends tablet css for hero component ********
