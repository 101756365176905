$enable-caret: true;
$enable-rounded: true !default;
$enable-new-search-layout: false;
$enable-shadows: true !default;
$enable-gradients: false;
$enable-transitions: true !default;
$enable-grid-classes: true;
$enable-print-styles: true;
$enable-navbar-animation: 0 !default;
$enable-container-padding-x: 1 !default;
$enable-paginate-spacing: 1 !default;
$enable-dropdown-caret: 1 !default;
// Color system

// $white: #fff !default;
// $gray-100:  rgba(255, 255, 255, 0.7); //#f8f9fa;
// $gray-200:  #F9F9FA ; //#f6f6f6
// $gray-300:  #9296A8; //#dee2e6;
// $gray-400: #EEEEF1; //#ced4da;
// $gray-500: #646a84; //#adb5bd;
// $gray-600:  #9296A8; //#6c757d;
// $gray-700:  #252D52; //#495057
// $gray-800:  #6E7997; //#343a40;
// $gray-900: #2E3852;
// $black: #252D52 !default; //#000

$white: #fff !default;
$gray-100:  #F9F9FA;  //#f8f9fa;
$gray-200: #C8CAD4;   //#f6f6f6
$gray-300:  #F6F6F7;  //#dee2e6;
$gray-400: #ced4da; //#ced4da;
$gray-500: #9296A8; //#adb5bd;
$gray-600: #6E7997;  //#6c757d;
$gray-700: #646A84;  //#495057
$gray-800: #2E3852;  //#343a40;
$gray-900: #252D52;
$black: #000 !default; //#000
$border-color-secondary: rgba($gray-700,0.1);
$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #3369d6;
$indigo: #5603ad;
$purple: #801096;
$pink: #e36397;
$red: #FF2D1B; //#e04f5f;
$orange: #ff3b00;
$yellow: #B86E1A; //#b86e1a;
$yellow-light: #EEA95B;
$green: #00c759;
$green-light: #20b566;
$green-dark: #009856; // #468320;
$teal: #17a2b8;
$cyan: #2bffc6;

$colors: ();
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "yellow-light": $yellow-light,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "light": $gray-400,
    "lighter": $gray-200,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: #2561ED !default;
$primary-light: rgba(37,45,82,.1) !default;
$primary-dark: darken($primary, 40%) !default;

$secondary:  $gray-700 !default;
$tertiary: #2E3852 !default; //#333333
$success: $green-dark !default;
$success-light: $success;
$info: $teal !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-700 !default;
$dark: $gray-900 !default;
$featured-green: $secondary;
$light-secondary:  rgba(46, 56, 82, 0.05);

$facebook: #3b5999;
$twitter: #1da1f2;
$google-plus: #dd4b39;
$instagram: #e4405f;
$pinterest: #bd081c;
$youtube: #cd201f;
$slack: #3aaf85;
$dribbble: #ea4c89;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "primary-light": $primary-light,
    "primary-dark": $primary-dark,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

$brand-colors: ();
$brand-colors: map-merge(
  (
    "facebook": $facebook,
    "twitter": $twitter,
    "google-plus": $google-plus,
    "instagram": $instagram,
    "pinterest": $pinterest,
    "youtube": $youtube,
    "slack": $slack,
    "dribbble": $dribbble,
  ),
  $theme-colors
);

// Action colors

$favorite-color: $yellow;
$like-color: $blue;
$love-color: $red;

// Body

$body-bg: $white !default;
$body-bg-secondary: #F9F9FA !default;
$body-color: $tertiary !default;
$black-50: rgba($black, 0.5) !default;

// Links

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline !default;
$link-visited-color: #489bd0 !default;

$trial-link-visited-color: $link-visited-color !default;
$trial-link-hover-color: #0c4584 !default;
// Spacing

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    "sm": (
      $spacer * 2,
    ),
    "md": (
      $spacer * 4,
    ),
    "lg": (
      $spacer * 6,
    ),
    "xl": (
      $spacer * 8,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);

//shadows
$box-shadow-sm: 0 0.06rem 0.65rem rgba($black, 0.09) !default;
$box-shadow: 0 0.1rem 0.16rem rgba($black, 0.09) !default;
$box-shadow-lg: 0 0.125rem 1.56rem -0.06rem rgba($black, 0.09) !default;
$cards-box-shadow: 0 0.25rem 0.25rem 0 rgba($gray-900, 0.04);
// Components

$line-height-xl: 1.5;
$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 0.0625rem;
$border-color: $gray-400;

$border-radius: 5px !default;
$border-radius-xl: $border-radius;
$border-radius-lg: $border-radius * 1.86 !default;
$border-radius-sm: 0.313rem !default;

$component-active-color: $white;
$component-active-bg: $primary;
$component-active-border-color: $primary;

$component-hover-color: $gray-300;
$component-hover-bg: $white;
$component-hover-border-color: $primary;

$caret-width: 0.3em;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

// Fonts
$font-name: "Inter" !default;
$font-name-alt: "Inter" !default;
$font-family-base: $font-name, -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-alt: $font-name-alt, SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;

$font-family-secondary: $font-name !default; // applies only to h1 to h6

$font-size-base: 0.9rem; // Assumes the browser default, typically `16px`
$font-size-xl: ($font-size-base * 1.5);
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 1);
$font-size-md: ($font-size-base * 1.1);
$font-size-xs: ($font-size-base * 0.875);
$font-size-xxs: ($font-size-base * 0.75);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 600 !default;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.6;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-font-weight-bold: 600 !default;
$headings-line-height: 1.5;
$headings-color: $gray-900 !default;
$h1-color: #252d52 !default; //#4f9725


$h1-font-size: $font-size-base * 3.1 !default;
$h2-font-size: $font-size-base * 2.23 !default;
$h3-font-size: $font-size-base * 1.67 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base  !default;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: ($font-size-base * 1);
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-700;

$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.1);

$hr-border-color: rgba($black, 0.05);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// Icons

$icon-size: 3rem;
$icon-size-xl: 5rem;
$icon-size-lg: 4rem;
$icon-size-sm: 2rem;

// Tables

$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;

$table-bg: transparent;
$table-accent-bg: rgba($black, 0.05);
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $gray-300;

$table-head-bg: $gray-200;
$table-head-color: $gray-700;

$table-dark-bg: $gray-900;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg;

// Buttons + Forms

$input-btn-padding-y: 0.7rem;
$input-btn-padding-x: 1rem;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0;
$input-btn-focus-color: rgba($component-active-bg, 1);
$input-btn-focus-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
//$input-btn-focus-box-shadow:  none;

$input-btn-padding-y-sm: 0.55rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.95rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-padding-y-xl: 1.15rem;
$input-btn-padding-x-xl: 1rem;
$input-btn-line-height-xl: $line-height-xl;

$input-btn-border-width: $border-width;

//container padding-margin:

$c-container-padding-y: 0 !default;

$width-container-2: 900px !default;
$width-container-main: 1140px !default;

$component-title-margin-bottom: 0 !default; //1.875rem 
//search-location-inputs

$search-location-outer-border-width: 1px !default;
$search-location-outer-radius: 5px !default;
$search-bg: transparent !default;
// Buttons

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x + 0.5 !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-padding-y-xl: $input-btn-padding-y-xl !default;
$btn-padding-x-xl: $input-btn-padding-x-xl !default;
$btn-line-height-xl: $input-btn-line-height-xl !default;

$btn-border-width: 1px !default;

$btn-font-weight: 600 !default;
$btn-box-shadow: $box-shadow !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;

$btn-active-box-shadow: none !default;
$btn-link-disabled-color: lighten($tertiary, 50%) !default;

// browse component

$browse-inner-div-text-align: center !default;
$browse-inner-div-height: 180px !default;
$browse-inner-div-width: 100% !default;
$browse-inner-div-border: 1px solid #ced4da !default;
$browse-inner-div-padding-x: 1.5rem !default;
$browse-inner-div-padding-y: 1.5rem !default;
$browse-inner-div-justify-content: center !default;
$browse-inner-div-align-items: center !default;
$browse-inner-div-background-size: cover !default;
$browse-inner-div-heading-hover-font-weight: bold !default;

$browse-inner-div-hover-box-shadow: $box-shadow-lg !default;
$browse-inner-div-hover-img-width: 500px !default;

$browse-nav-link-background :#E5EDFA 0% 0% no-repeat padding-box !default;
$browse-nav-link-color: $primary !default;
$browse-nav-link-font-weight: 600 !default;

$browse-item-img-height: 70px !default;
$browse-item-img-width: 70px !default;
$browse-item-img-margin: 10px 10px 10px 5px !default;
$browse-item-img-object-fit: cover !default;

$browse-department-heading-color: $white;
$browse-investigator-heading-color: $primary;

$browse-dropdown-color: $primary !default;
$browse-dropdown-font-weight: bold !default;
$browse-dropdown-background: $white 0% 0% no-repeat padding-box !default;
$browse-dropdown-border-radius: 6px !default;
$browse-dropdown-menu-show-padding: 0 !default;

//browse component mobile

$browse-heading-flex-flow-mobile: column !default;

$browse-inner-div-text-align-mobile: left !default;
$browse-inner-div-height-mobile: 70px !default;
$browse-inner-div-border-radius-mobile: 5px !default;
$browse-inner-div-padding-left-mobile: 5px !default;
$browse-inner-div-justify-content-mobile: start !default;

$browse-item-img-height-mobile: 50px !default;
$browse-item-img-width-mobile: 50px !default;

// hero component
$hero-box-width: 100% !default;
$hero-box-height: auto !default;
$hero-content-width: 40vw !default;

$hero-component-default-background: $primary !default;

$hero-card-margin-top: 0 !default;
$hero-card-margin-right: 4% !default;
$hero-card-margin-bottom: 0 !default;
$hero-card-margin-left: 4% !default;

$learn-more-btn-color-sm: $primary !default;
$learn-more-border-color-sm: $primary !default;
$learn-more-button-background: transparent !default;
$learn-more-button-font-size: $font-size-md !default;

$hero-heading-font-size: $h1-font-size !default;
$hero-description-font-size: $font-size-lg !default;

$btn-learn-more-border-radius: 5px !default;
$btn-learn-more-color: $white !default;
$btn-learn-more-border-color: $white !default;

$hero-component-height: 32vw !default;

$hero-card-height: 24vw !default;
$hero-card-width: 30rem !default;
$hero-card-overflow: hidden !default;
$hero-card-padding-top: 2% !default;
$hero-card-padding-right: 5% !default;
$hero-card-padding-bottom: 5% !default;
$hero-card-padding-left: 5% !default;
$hero-card-background: $primary !default;

$hero-heading: $white !default;
$hero-description: $white !default;
$hero-content-align: left !default;

// $hero-component-padding-top: 2% !default;
// $hero-component-padding-right: 2% !default;
// $hero-component-padding-bottom: 2% !default;
// $hero-component-padding-left: 2% !default;

$hero-heading-padding-top: 2% !default;
$hero-heading-padding-right: 0% !default;
$hero-heading-padding-bottom: 5% !default;
$hero-heading-padding-left: 0% !default;

$hero-description-padding-top: 0px !default;
$hero-description-padding-right: 0% !default;
$hero-description-padding-bottom: 5% !default;
$hero-description-padding-left: 0% !default;

$hero-content-alignment: flex-start !default;
$hero-content-button-align: left !default;

$hero-background-size: cover !default;
$c-mb-4: 1.5rem !default;
$c-mt-4: 2.5rem !default;
//  hero mobile
$hero-component-mobile-height: 50vw !default;
$hero-content-align-mobile: left !default;
$learn-more-button-font-size-mobile: $font-size-sm !default; 
$hero-heading-font-size-mobile: $font-size-md !default;
$hero-description-font-size-mobile: $font-size-md !default;
$hero-card-height-mobile: auto!default;
$hero-card-width-mobile: 100% !default;
$hero-card-color-sm: $primary !default;

//  hero tablet
$hero-component-tablet-height: 56vw !default;
$hero-content-align-tablet: left !default;
$learn-more-button-font-size-tablet: $font-size-md !default; 
$hero-heading-font-size-tablet: $h2-font-size !default;
$hero-description-font-size-tablet: $font-size-lg !default;
$hero-card-height-tablet: 35rem !default;
$hero-card-width-tablet: 30rem !default;


// Forms

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-padding-y-xl: $input-btn-padding-y-xl !default;
$input-padding-x-xl: $input-btn-padding-x-xl !default;
$input-line-height-xl: $input-btn-line-height-xl !default;

$input-border-radius: $border-radius !default;
$input-border-radius-xl: $border-radius-xl !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-color: $gray-900 !default;
$input-border-color: $border-color;
$input-outlined-border-color: $gray-300 !default;
$input-border-width: $input-btn-border-width * 2 !default;
$input-box-shadow: none !default;

$input-focus-bg: $white !default;
$input-focus-border-color: $primary !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-500  !default; //$gray-300

$input-height-border: $input-border-width * 2 !default;

$input-transition: $transition-base !default;

// Input groups

$input-group-addon-color: $primary !default;
$input-group-addon-bg: $white;
$input-group-addon-border-color: $white;

$input-group-addon-focus-color: $primary;
$input-group-addon-focus-bg: $white;
$input-group-addon-focus-border-color: $primary;
// Custom forms

$custom-control-gutter: 0.25rem;
$custom-control-spacer-x: 1rem;
$custom-control-label-disabled-color: lighten($tertiary, 50%);
$custom-control-indicator-size: 1.25rem;

$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-border-width: $input-border-width;
$custom-control-indicator-border-color: $border-color;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-box-shadow: none;

$custom-control-indicator-hover-color: $component-hover-color;
$custom-control-indicator-hover-bg: $component-hover-bg;
$custom-control-indicator-hover-border-color: $component-hover-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: $component-active-bg;
$custom-control-indicator-active-border-color: $component-active-border-color;
$custom-control-indicator-active-box-shadow: none;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-border-color: $component-active-border-color;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 24 24'><path fill='#{$custom-control-indicator-checked-color}' d='M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z'/></svg>");
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='-4 -4 8 8'><circle r='2' fill='#{$custom-control-indicator-checked-bg}'/></svg>");
$custom-control-indicator-bg-size: 100% 100%;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $input-border-radius !default;
$btn-border-radius-xl: $input-border-radius-xl;
$btn-border-radius-lg: $input-border-radius-lg;
$btn-border-radius-sm: $input-border-radius !default;

// No UI Slider

$noui-target-bg: $gray-300;
$noui-target-thickness: $custom-control-indicator-border-width;
$noui-target-border-radius: 4px;
$noui-target-border-color: darken($border-color, 3%);

$noui-slider-connect-bg: $primary;
$noui-slider-connect-disabled-bg: #b2b2b2;

$noui-handle-bg: #fff;
$noui-handle-border-color: #d9d9d9;
$noui-handle-border-radius: 100%;

$noui-origin-border-radius: 2px;

// Dropdown

$dropdown-bg: $white !default;
$dropdown-border-width: 0;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius;
$dropdown-box-shadow: $box-shadow !default;

//Dropdown colors
$dropdown-link-active-color: $input-color;
$dropdown-link-active-bg: rgba($gray-200, .22);
$dropdown-link-disabled-color: $input-placeholder-color;
$dropdown-caret-color: $primary;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: $primary-light;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1rem;


// Navs

$nav-link-padding-y: 0.2rem;
$nav-link-padding-x: $spacer;
$nav-link-color: $gray-900  !default; //$gray-700
$nav-link-disabled-color: $gray-600;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Navbar
$navbar-bg: $white !default;
$navbar-shadow: 0 0.1rem 0.4rem rgba($black, 0.05) !default;
$navbar-transition: all 0.15s linear;
$navbar-padding-y: 1rem;
$navbar-padding-x: 1rem;
$header-position: 'relative' !default;
$nav-active-font-weight :bold !default;
$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 0.75rem;
$nav-active-underline-color: $primary;
$nav-active-underline-width: 0px !default;
// $navbar-nav-link-font-family:       $font-family-base;
$navbar-nav-link-font-size: 0.9rem !default;
$navbar-nav-link-font-weight: 500;
$navbar-nav-link-text-transform: normal;
$navbar-nav-link-letter-spacing: 0;
$navbar-nav-link-border-radius: $border-radius;

$navbar-dark-bg: transparent;
$navbar-dark-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-active-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
//$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-dark-toggler-border-color: transparent;

$navbar-light-color: $gray-900 !default;
$navbar-light-hover-color: rgba($black, 0.7) !default;
$navbar-light-active-color: $white !default;
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
);
$navbar-light-toggler-border-color: rgba($black, 0.1);
$navbar-toggler-bg: transparent !default;
$navbar-toggler-color: $primary !default;

//search Component
$search-outer-box-shadow: none !default; //0 4px 14px 0 #2c393f12
// Alerts
$component-padding-y: 0 !default; //20px 
$alert-padding-y: 1rem;
$alert-padding-x: 1.5rem;
$alert-border-radius: $border-radius;

$alert-bg-level: -10;
$alert-border-level: -10;
$alert-color-level: 0;

// Close

$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-bg: rgba(0, 0, 0, 0.3);
$close-hover-bg: rgba(0, 0, 0, 0.5);
$close-color: rgba(255, 255, 255, 0.6);
$close-text-shadow: none;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 0.25rem;
$tooltip-padding-x: 0.5rem;
$tooltip-margin: 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;

// Popovers

$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: 1px;
$popover-border-color: rgba($black, 0.05);
$popover-border-radius: $border-radius-lg;
$popover-box-shadow: 0px 0.5rem 2rem 0px rgba($black, 0.2);

$popover-header-bg: $popover-bg;
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.75rem;
$popover-header-padding-x: 0.75rem;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 1.5rem;
$popover-arrow-height: 0.75rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: transparent;

// Badges

$badge-font-size: 100%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.25rem;
$badge-padding-x: 0.375rem;
$badge-border-radius: $border-radius !default;
$badge-color: $primary !default;
$badge-bg: transparent !default;

$badge-pill-padding-x: 0.65em;
$badge-pill-border-radius: 10rem;

// Pagination

// $pagination-padding-y:              .5rem;
// $pagination-padding-x:              .75rem;
// $pagination-padding-y-sm:           .25rem;
// $pagination-padding-x-sm:           .5rem;
// $pagination-padding-y-lg:           .75rem;
// $pagination-padding-x-lg:           1.5rem;
// $pagination-line-height:            1.25;

$pagination-color: $gray-900 !default;
$pagination-bg: $body-bg !default;
$pagination-border-width: 1px !default;
$pagination-border-color: $border-color;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;

$pagination-hover-color: $primary;
$pagination-hover-bg: rgba($gray-500,.3);
$pagination-hover-border-color: $border-color;

$pagination-active-color: $white;
$pagination-active-bg: $primary !default;
$pagination-active-border-color: $gray-300;

$pagination-disabled-color: $gray-500;
$pagination-disabled-bg: transparent !default;
$pagination-disabled-border-color: $gray-300;

// Cards

$card-spacer-y: 1.5rem !default; //2rem
$card-spacer-x: 1.5rem !default; //2rem
$card-border-width: $border-width * 0 !default;
$card-border-radius: $border-radius-lg !default;
$card-border-color: rgba($black, 0.05) !default;
$card-inner-border-radius: calc(
  #{$card-border-radius} - #{$card-border-width}
) !default;
$card-cap-bg: transparent !default;
$card-cap-color: $headings-color !default;
$card-bg: transparent !default;
$card-box-shadow: none !default;
$card-hover-box-shadow: $box-shadow-lg !default;
$card-transition: $transition-base !default;

$card-img-overlay-padding: 1.25rem;

//$card-group-margin:                 ($grid-gutter-width / 2);
//$card-deck-margin:                  $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;
//
$c-container-padding-x: $card-spacer-x !default;
// Modals

$modal-inner-padding: 1.25rem !default;

$modal-md: 700px !default;


// $modal-dialog-margin:         .5rem;
// $modal-dialog-margin-y-sm-up: 1.75rem;

//$modal-title-line-height:           $line-height-base;

$modal-content-bg: $white !default;
$modal-content-border-width: 1px;
$modal-content-border-radius: $border-radius;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5);

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.3;
$modal-header-border-color: $white !default;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: 1.25rem !default;

//Aside Modal


$aside-close-modal-bg : $white ;

$aside-container-bg: $body-bg-secondary !default;

// Footer

$footer-bg: $gray-200;
$footer-color: $gray-600;
$footer-link-color: $gray-600 !default;
$footer-link-color-hover: $primary !default;
$footer-link-decoration: underline !default;
$footer-link-decoration-hover: underline !default;

//Nav Tabs
$nav-tabs-border-radius: none;

//Chip colors
$chip-bg: $gray-200 !default;
$chip-color: $tertiary !default;
$chip-active-bg: $primary !default;
$chip-active-color: $white !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
) !default;

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

$input-group-box-shadow: $input-box-shadow;
$input-group-focus-border-color: $primary;
$tertiary-light: lighten($tertiary, 30%);
$select-box-shadow: $input-group-box-shadow;
$width-default: 100%;
$font-family-sans-serif: $font-family-base;
$font-family-monospace: $font-family-alt;
$font-weight-semi-bold: 600;
$drawer-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
// Custom
$header-height: 122px;

//Slick Theme Variables
$slick-arrow-color: $tertiary;
$slick-next-character: url('data:image/svg+xml,<svg fill="currentColor" height="20" width="20" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon points="13.854,48.707 37.561,25 13.854,1.293 12.439,2.707 34.732,25 12.439,47.293  "/></g></svg>');
$slick-prev-character: url('data:image/svg+xml,<svg fill="currentColor" height="20" width="20" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon points="37.561,47.293 15.267,25 37.561,2.707 36.146,1.293 12.439,25 36.146,48.707  "/></g></svg>');

//list group

$list-group-border-radius: $border-radius !default;
// $list-group-bg:                 $light !default;
// $list-group-border:             #ddd !default;
// $list-group-hover-bg:           #f5f5f5 !default;
// $list-group-active-color:       $primary !default;
// $list-group-active-bg:          $light !default;
// $list-group-active-border:      $list-group-active-bg !default;
// $list-group-active-text-color:  darken($list-group-active-bg, 40%) !default;
// $list-group-disabled-color:      $gray-light !default;
// $list-group-disabled-bg:         $gray-lighter !default;
// $list-group-disabled-text-color: $list-group-disabled-color !default;
// $list-group-link-color:         #555 !default;
// $list-group-link-hover-color:   $list-group-link-color !default;
// $list-group-link-heading-color: #333 !default;

//Font Awesome Family
$font-awesome: "Font Awesome 5 Free";
$font-awesome-font-weight-solid: 900;
$font-awesome-font-weight-regular: 500;

//caret
$caret-size: 10px;
$caret-position: 1.5rem;

//Breadcrumbs
$breadcrumb-divider: quote("/");
$breadcrumb-bg: transparent;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;

$browse-link-color: $primary !default;
$w-py-4: 0 !default;
//nav tabs
$nav-tabs-hover-bg: lighten($primary, 60%) !default;
$nav-tabs-selected-bg: $white !default;
$nav-tabs-active-bg: lighten($primary, 55%) !default;

$dinein-logo-height: 60px !default;
//collapse background
$collapse-description-bg: $gray-100;
$collapse-bg: $primary !default;
$collapse-hover-bg: $primary !default;
$collapse-border-width: 0px !default;
$collapse-border-color: $border-color !default;
$collapsed-bg: $primary !default;
$unicode-for-accordion: \f067 !default; //f107
$unicode-for-collapsed-accordion: \f068 !default; //f106
$collapse-margin-bottom: 0 !default;
$collapse-heading-color: $h1-color;
$collapse-expanded-heading-color: $h1-color !default;
$accordion-padding-y:.5rem !default;
$accordion-padding-x:.5rem !default;
$accordion-wrapper-bg: $white !default;

//testimonials
$carousel-bg: $primary !default;
$carousel-text-color: $white !default;
$testimonial-image-radius: 50% !default;
$fa-quote-color: $white !default;
$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;
$display-carousel-indicators: none !default;
$testimonial-block-row-direction: row !default;
$carousel-indicator-active-bg: $white !default;

//recently-updated-trials
$trial-card-border-width: 1px !default;
//trial details background
$trial-content-bg-color: $white !default;
$trial-content-padding-x: 0px !default;
$trial-content-padding-y: 0px !default;
$trial-content-box-shadow: $box-shadow-lg !default;
$trial-content-border-radius: $border-radius !default;
$learn-more-font-size: 1rem !default;

$btn-text-transform: none !default;
$trial-icon-color: $tertiary !default;

$nav-item-active-bg: $primary !default;

$dine-in-footer-bg: #dadada !default;
$dine-in-footer-color: $tertiary !default;

//Exit/Disclaimer Modal 
$max-height-disclaimer-modal: 450px !default;
$height-disclaimer-modal: auto;
//General Modal 
$max-height-general-modal: 550px !default;
$height-general-modal: auto;


$browse-inner-div-padding-bottom: 0px !default;
$browse-inner-div-padding-top: 0px !default;