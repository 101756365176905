//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/
@import 'variables/takeout/_third_party_theme_vars.sass';
@import 'variables/takeout/_theme_vars.scss';
// @import 'fonts/_fonts.sass'
@import 'modules/_config.sass';

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bs-stepper/dist/css/bs-stepper.min.css";
@import "~animate.css";
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";


// SASS MIXINS
//----------------------------------*/

@import 'mixins/_color.sass';
@import 'mixins/_position.sass';
@import 'mixins/_breakpoints.sass';

//----------------------------------*\
// PARTIALS
//----------------------------------*/
@import 'partials/_sidebar.sass';
@import 'partials/_main.sass';

//----------------------------------*\
// OBJECTS & MODULES
//----------------------------------*/

@import 'modules/_buttons.sass';
@import 'modules/_inputs.sass';
@import 'modules/_lists.sass';
@import 'modules/_typography-takeout.sass';
@import 'modules/_misc.sass';
@import 'modules/_takeout-app.sass';

// Enhancement modules
@import 'modules/_breakpoints.sass';
@import 'modules/_print.sass';

//----------------------------------*\
// VENDOR
//----------------------------------*/
@import 'components/_components.sass';

// Custom component overide
@import 'components/_bootstrap-select.sass';
@import 'components/_google-autocomplete.sass';

@import 'vendor/_external.sass';
@import 'components/_intl-tel-input.scss';
@import 'components/_avatar.sass';
@import 'components/_select2.sass';
@import 'components/_hero-component.sass';
@import 'components/_browse-component.sass';
