.btn-navlink {
    padding: $btn-padding-y $btn-padding-x;
    @extend .btn-outline-primary;
    &.active {
        @extend .btn-primary; } }
.h1-color {
    color: $h1-color; }
h1, h2, h3 {
    font-weight: $headings-font-weight-bold; }
.browse-links {
    a {
        color: $browse-link-color; } }
.navbar {
    .navbar-toggler {
        background: $navbar-toggler-bg;
        color:  $navbar-toggler-color; }
    .menu-dropdown {
        .dropdown-menu {
            background: #fff;
            box-shadow: 0 10px 8px 0 rgba(37,45,82,.1);
            border-radius: 5px;
            margin-top: 0;
            min-width: 200px;
            max-width: 300px;
            padding: 5px; }
        .nav-link {
            span {
                margin-right: 3px; } }

        .dropdown-submenu-left {
            margin-right: 1px; } }
    .navbar-nav {
        .dropdown.show {
            .nav-link {
                  color: inherit; } } } }


.navbar-brand {
    img {
       height: $dinein-logo-height;
       transition: all 1s ease-out; }


    .receeded-height {
        @if $enable-navbar-animation == 1 {
            height: calc(#{$dinein-logo-height} - 1.1rem); } } }

.c__mb-4 {
    margin-bottom: $c-mb-4; }
.c__mt-4 {
    margin-top: $c-mt-4; }


.c__container {
        @extend .container;
        @if $enable-container-padding-x == 1 {
                padding:  $c-container-padding-y $c-container-padding-x; }
        @else {
                padding: $c-container-padding-y 0; } }
.c__container-2 {
        @extend .container; }

.navbar {
        .c__container {
            @if $enable-container-padding-x == 1 {
                padding:  $c-container-padding-y $c-container-padding-x; }
            @else {
                padding: $c-container-padding-y $card-spacer-x; } } }
#hero {
        .c__container {
            @if $enable-container-padding-x == 1 {
                padding:  $c-container-padding-y $c-container-padding-x; }
            @else {
                padding: $c-container-padding-y $card-spacer-x; } } }

.build_status {
    background: #3369d6;
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 1.2em; }

.search-item-link:visited h5, .learn-more-link:visited {
        color: $trial-link-visited-color; }
.search-item-link:hover h5, .learn-more-link:hover {
        color: $trial-link-hover-color; }
.trial-content {
    background: $trial-content-bg-color;
    padding: $trial-content-padding-y $trial-content-padding-x;
    box-shadow: $trial-content-box-shadow;
    border-radius: $trial-content-border-radius; }

.btn {
    text-transform: $btn-text-transform; }
.trial-icon {
    color: $trial-icon-color; }


.trial-description, .find-site {
    .collapse-caret-add-toggle:after ,.collapse-caret-add-toggle[aria-expanded=true]:after {
        color: $white;
        font-size: 1rem; } }

.navbar-nav .nav-item {
    margin-top: 0.5rem;
    font-size: $navbar-nav-link-font-size;
    .nav-button {
        padding: 0.625rem;
        border-radius: $btn-border-radius;
        font-size: $navbar-nav-link-font-size; }


    &.active {
        border-radius: $border-radius;
        background-color: $nav-item-active-bg;
        padding: 0.5rem 1rem;


        & > .nav-link {
             font-weight:  $nav-active-font-weight;
             &::after {
                border-bottom: $nav-active-underline-width solid $nav-active-underline-color;
                content: " ";
                display: block; } } }

    @include media-breakpoint-up(sm) {
        margin-left: 0.25rem;
        margin-top: 0; }

    button {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; } }


.dinein-footer {
    background: $dine-in-footer-bg;
    color:  $dine-in-footer-color; }


.footer-nav-link {
    color: $footer-link-color;
    text-decoration: $footer-link-decoration;
    &:hover {
       text-decoration: $footer-link-decoration-hover;
       color: $footer-link-color-hover; } }


#eligibility {
    pre {
       font-family: $font-family-base;
       font-size: $font-size-base;
       margin-left: -2rem; } }

#disclaimerText .modal-body, #exitModal .modal-body {
    max-height: $max-height-disclaimer-modal;
    height: $height-disclaimer-modal; }
/* trials main  */
.trial_site_picture {
    border-radius: 0.3125rem; }
.last-updated-container {
    background: $light-secondary;
    border-radius: 10px; }
.pinv {
    h5 {
        font-size: 15px; }
    .avatar-pi {
        width: 43px;
        height: 43px;
        margin-right: 8px; } }
.pi-name {
    color: $gray-700; }
.custom-avatar-pi {
    background: #8AA9EF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    margin-right: 8px;
    width: 43px;
    height: 43px;
    span {
        font-size: 16px; } }

// Aside Modal

.bubble {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1049;
    .modal-icon {
            margin-bottom: 1.25rem;
            width: 50px;
            height: 50px;
            border: 1px solid $border-color;
            border-radius: $border-radius 0 0 $border-radius;
            box-shadow: 5px 16px 12px 0px rgba($gray-900,.16);
            background: $white;
            -webkit-appearance: none;
            svg {
                width: 35px;
                height: 35px;
                path {
                    stroke: $primary; } } } }




#rightModal {
    .modal {
        position: unset; }
    .modal-dialog {
        right: 0;
        top: 0;
        transform: translateY(0); }

    .modal.fade {
       .modal-dialog {
          transition: transform .3s ease-out;
          transform: translateY(-50%); } }


    .modal-content {
        width: 310px;
        padding: 20px;
        box-shadow: $modal-content-box-shadow-sm-up;
        border: 1px solid $border-color;
        border-radius: $border-radius  0 0  $border-radius;
        svg {
            width: 70px;
            height: 70px; } }

    .close-modal {
            background: $aside-close-modal-bg;
            top: 0;
            left: -74px;
            box-shadow: -5px 10px 20px rgba($black, 0.5);
            width: 75px;
            .right-end-shadow {
                background: $gray-700;
                width: 4px;
                height: 75px; }
            .close-icon {
             top: 50%;
             left: 50%;
             transform: translate(-50%,-50%);
             pointer-events: auto; } } }


.modal-body {
    max-height: $max-height-general-modal;
    height: $height-general-modal; }
#registration_info, .registration_success_text {
    border-radius: 5px; }
.bs-stepper {
    padding: 1.25rem 1.875rem;
    background: $gray-100;
    border: 1px solid $border-color;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;
    .step-trigger {
        padding: 0;
        font-size: 0.9rem;
        &:hover {
            background: none; } } }
.select2-container {
    width: 100%;
    min-width: 100%; }
.select2-hidden-accessible {
    visibility: hidden;
    height: 0; }
.select2-container--default .select2-selection--multiple {
    border: .125rem solid #2a3d83 !important; }
.vphone-input {
    .iti {
        width: 100% !important; } }

.accordion-outer {
    background: $accordion-wrapper-bg;
    .accordion-card {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        .accordion-title {
            padding: 1.875rem;
            margin: 0;
            background: $white;
            border-radius: $border-radius;
            &:after {
                right: 1.875rem;
                top: 1.875rem;
                font-size: $font-size-base; } }
        .collapse-description {
            padding: 0  1.875rem 1.875rem 1.875rem;
            .inner-accodion-div {
                border-left: 2px solid rgba($primary,0.5); }
            border-radius: 0 0 $border-radius $border-radius; } }

    .accordion-title[aria-expanded="true"] {
        background: $gray-100;
        padding-bottom: 1.25rem; } }




//testimonial
.carousel-container {
    background: $carousel-bg; }
.carousel {
   .carousel-indicators {
        display: $display-carousel-indicators;
        li {
          border-radius: 50%; } }

   .carousel-item {
          color: $carousel-text-color; } }

.testimonial-block-test {
            flex-direction: $testimonial-block-row-direction;
            .carousel-image {
                     width: 100%;
                     border-radius: $testimonial-image-radius;
                     max-height: 260px;
                     max-width: 260px; }
            .fa-quote-left {
                    font-size: 2rem;
                    color: $fa-quote-color; } }


.testimonial-name {
        font-size: $font-size-xl;
        font-weight: 600; }

.testimonial-designation {
        font-size: $font-size-lg; }
.component-title {
    margin-bottom: $component-title-margin-bottom; }
.search-outer {
    z-index: 10;
    box-shadow: $search-outer-box-shadow;
    @if $enable-new-search-layout {
        background: $search-bg; }
    .c__container {
        padding-top: $component-padding-y;
        padding-bottom:  $component-padding-y; } }


.wrapper {
    padding-top: $w-py-4;
    padding-bottom: $w-py-4;
    overflow-x: hidden; }

#find-study-hero {
    .custom-input {
        .input-group-text {
            padding: .7rem 1rem; }
        @if $enable-new-search-layout {
            border: none; } }

    .search-location {
            @if $enable-new-search-layout {
                border: $search-location-outer-border-width solid $border-color;
                background: #fff;
                padding: 5px;
                border-radius: $btn-border-radius; }
            &:focus-within {
                border-color: $primary; } }

    .divider {
        @if $enable-new-search-layout {
            display:block {} }
        @else {
            display: none; }
        width: 1px;
        background: $input-border-color;
        margin: .5rem 0; }

    .btn-block {
        @if $enable-new-search-layout {
            max-width: 128px; } } }

.recent-trials-card {
    border: $trial-card-border-width solid $border-color-secondary;
    background: $white;
    border-radius: $trial-content-border-radius;
    padding: 1.125rem;
    box-shadow: $cards-box-shadow;
    transition: 1s box-shadow;
    position: relative;
    min-height: 268px;
    &:hover {
        box-shadow: 0px 10px 20px $gray-200;
        .search-item-link {
             text-decoration: underline;
             text-decoration-color: $gray-900; } }
    .trial-image {
        border-radius: $border-radius-sm;
        object-fit: cover;
        width: 324px;
        aspect-ratio: 4/3; }
    .description-container {
        padding-left: 20px;
        color: $gray-700;
        gap: 12px; }



    .search-item-link {
        height: 44px;
        width: fit-content;
        h5 {
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden; } }
    .icons-container {
        gap: 8px;
        padding: 0.625rem 0;
        flex-wrap: wrap;
        .icon-title {
            color: $gray-700;
            padding: 5px 12px;
            background: #eeeef1;
            border-radius: 20px;
            display: inline-block; } }
    .trial-content-container {
        gap: 10px; }

    .search-item--decription {
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: $gray-700; }

    .avatar-inv {
        width: 22px;
        height: 22px;
        margin-right: 3px; }
    .custom-avatar {
        background: #8AA9EF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        margin-right: 5px; }
    .learn-location-container {
        gap: 10px;
        flex-wrap: wrap; }
    .p-inv-container {
        gap: 8px;
        flex-wrap: wrap; }
    .study-container {
        gap: 5px; } }



.featured-trials-container {
    background: $primary;
    .featured-trial-card {
         width: 350px;
         background: #fff;
         height: 30rem;
         border-radius: 5px;
         img {
            height: 10.75rem;
            object-fit: cover; } } }
.owl-carousel {
    .owl-nav {
        .owl-prev, .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%); }
        .owl-prev {
            left: -25px; }
        .owl-next {
            right: -25px; } }
    .owl-dots {
        margin-top: 3.125rem; } }



@include media-breakpoint-down(xs) {
    .featured-trials-container {
        background: transparent;
        .featured-trial-card {
            width: 100%; } }
    .c__pt-20 {
        padding-top: 1.25rem; }
    #rightModal {
        .modal-dialog {
            top: 50%;
            right: 3px;
            transform: translate(0%, 50%); }
        .modal-content {
            width: 97vw;
            border-radius: $border-radius; } } }



.owl-nav {
     display: none; }





.aside_container_left {
    background-color: $aside-container-bg;
    box-shadow: $cards-box-shadow;
    padding: 1rem;

    .aside-location-container {
        @if $aside-container-bg == transparent {
            padding-top: 0; }

        .select-custom-btn {
            padding: $input-padding-y $input-padding-x; } } }
.takeout-pagination {
    @if $enable-paginate-spacing == 1 {
        .page-item {
            .page-link {
                margin-left: 0.25rem;
                border-radius: 0.313rem;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-size-xs; } } } }

.google-autocomplete-input {
        .input-icon {
            padding: .9rem 1rem;
            i {
                color: $input-group-addon-color; } }
        .form-control {
            padding-left: 2.85rem; } }

.divider-login {
    border-left: 1px solid $border-color; }

.input-group-custom {
    .dropdown-menu.show {
        padding: 0.313rem; } }

.btn {
    box-shadow: none; }

.bootstrap-select {
    .dropdown-menu.inner {
        padding: 0.313rem; } }

.search-icon-sm {
    font-size: 1.2rem; }
.search-icon-lg {
    font-size: 1.74rem; }
.breadcrumb-item.active {
    a {
        color: inherit; } }

@media screen and (min-width: 1200px) {
    .c__container {
        max-width: $width-container-main;
        @if $enable-container-padding-x == 1 {
            padding: $c-container-padding-y $c-container-padding-x; }
        @else {
            padding: 0; } }
    .c__container-2 {
        max-width: $width-container-2;
        @if $enable-container-padding-x == 1 {
            padding:  $c-container-padding-y $c-container-padding-x; } }

    .navbar {
        .c__container {
            @if $enable-container-padding-x == 1 {
                padding:  $c-container-padding-y $c-container-padding-x; }
            @else {
                padding: $c-container-padding-y $card-spacer-x; } } }
    #hero {
        .c__container {
            @if $enable-container-padding-x == 1 {
                padding:  $c-container-padding-y $c-container-padding-x; }
            @else {
                padding: $c-container-padding-y $card-spacer-x; } } } }

@media screen and (max-width: 991px) {
    .navbar {
        .navbar-nav {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem; }
        .dropdown-item.nav-link:hover {
            background: transparent; }
        .menu-dropdown {
            .dropdown-menu {
                width: auto;
                background: transparent;
                box-shadow: none;
                margin-top: 0;
                margin-left: 18px; }

            .nav-link {
                width: fit-content;
                span {
                    margin-right: 3px; }
                &[aria-expanded="true"] {
                        background: rgba(0,0,0,.07);
                        border-radius: 5px;
                        margin-top: .5rem;
                        &:after {
                            right: 1rem;
                            transform: translateY(-50%); }
                        span {
                            margin-right: 16px; } } } }
        .nav-item {
            margin-top: 0;
            .nav-link {
                padding: 10px 14px; } }


        .nested-dropdown {
            margin-top: 7px; } } }



@media screen and (min-width:991px) {
    .navbar {
        .dropdown-menu li {
            position: relative; }

        .dropdown-menu .dropdown-submenu {
            display: none;
            position: absolute;
            left: 100%;
            top: -7px; }

        .dropdown-menu .dropdown-submenu-left {
            right: 100%;
            left: auto; }

        .dropdown:hover>.dropdown-menu {
            display: block; }


        .dropdown>.dropdown-toggle:active {
            pointer-events: none; }

        .dropdown-menu > li:hover > .dropdown-submenu {
            display: block; }

        .nav-link.dropdown-toggle:after {
            @if $enable-dropdown-caret == 1 {
                content: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPScjNjQ2QTg0JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgLTQgMjQgMjQnPjxwYXRoIGQ9J00wIDcuMzNMMi44MjkgNC41bDkuMTc1IDkuMzM5TDIxLjE3MSA0LjUgMjQgNy4zMyAxMi4wMDQgMTkuNXonLz48L3N2Zz4='); }
            @else {
                content: ' '; } }
        .menu-dropdown {
            .dropdown-item.dropdown-toggle:after {
                content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0idG9nZ2xlX2ljb24gYmkgYmktY2FyZXQtcmlnaHQiIGRhdGEtdHgtY291bnRyeS1zdWItdG9nZ2xlLWljb249IiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjY0NiAxLjY0NmEuNS41IDAgMCAxIC43MDggMGw2IDZhLjUuNSAwIDAgMSAwIC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDgtLjcwOEwxMC4yOTMgOCA0LjY0NiAyLjM1NGEuNS41IDAgMCAxIDAtLjcwOHoiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDwvc3ZnPg=='); } }

        .dropdown-submenu {
                .nav-link.dropdown-toggle:after {
                    right: 1rem; } }
        .dropdown-item.nav-link:hover {
                background: rgba(0,0,0,.07); }
        .dropdown-item {
                padding: 10px; } } }


.form-control[readonly="readonly"] {
    background-color: transparent; }
#site-form {
    .form-field-custom {
        .input-group-custom {
            border: 1px solid $border-color;
            border-radius: $border-radius-sm !important;
            .form-control {
                border: 0;
                border-radius: $border-radius-sm;

                &:focus {
                    border: 0 !important; } }
            &:focus-within {
                border: 1px solid  $primary; } }
        textarea {
            border: 1px solid $border-color;
            border-radius: $border-radius-sm !important;
            &:focus {
                border-color: $primary !important; } } } }
#trial-navigation {
    z-index: 999;
    background: $gray-100;
    border: 1px solid $border-color;
    border-radius: $border-radius; }






.nested-dropdown {
    margin-left: 8px;
    position: relative; }

.country-drpdwn-btn {
    width: 165px;
    justify-content: space-between;
    border-radius: 5px;
    padding: 10px;
    height: 37px;
    border: 1px solid $border-color; }


.country-list {
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    margin-top: 14px;
    width: 250px;
    padding: 10px;
    background: $white;
    box-shadow: $box-shadow;  //0px 4px 8px rgba(51,51,51,.28)
    border-radius: $border-radius-sm; }


.language-list, .mobile-language-list {
        position: absolute;
        z-index: 99;
        display: flex;
        flex-direction: column;
        right: 100%;
        left: auto;
        margin: 4px;
        margin-top: 20px;
        width: 196px;
        background: $white;
        box-shadow: $box-shadow; //0px 4px 8px rgba(51, 51, 51 ,.13)
        border-radius: 5px;
        padding: 10px; }

.mobile-language-list {
        right: 0;
        left: 0;
        margin: 0;
        display: flex; }

.toggle_icon {
        pointer-events: none;
        margin-left: 5px;
        color: $gray-600; }

.country-name {
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px; }

.country-btn {
        width: 100%;
        justify-content: center;
        border-radius: 5px;
        padding: 10px  !important; }

.nested-dropdown  .language-btn {
        width: 100%;
        text-align: left;
        padding: 10px;
        border-radius: 5px; }

.country-btn:hover, .language-btn:hover {
        background:  $gray-300; }



.country-flag {
        width: 1.25rem;
        pointer-events: none; }

.country-btn.active, .language-btn.active {
        background-color:  $gray-300; }

@media only screen and (max-width: 600px) {
    .mobile-language-list {
        left: 0;
        margin-top: 14px; }


    .country-list {
        left: 0;
        width: 220px; } }



#contact-site-form {
    .alert-success {
        background: lighten($green-light, 45%);
        border: none;
        color: $success;
        padding: 7px 14px !important;
        border-radius: $border-radius;
        margin-bottom: 15px;
        &:before {
            display: none; } } }

@media screen and (max-width: 768px) {
    .recent-trials-card {
       height: auto;
       .description-container {
            padding-left: 0; }
       .trial-image {
            height: 250px;
            width: 100%; } } }

@media screen and (max-width: 480px) {
    .p-inv-container,.icons-container {
        flex-direction: column;
        gap: 10px; }
    .learn-location-container {
        flex-direction: column;
        a {
           width: 100%; } }
    .recent-trials-card {
        .trial-image {
            height: 180px; }
        .study-container {
            width: 100%; } } }
