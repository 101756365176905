.alert {
    @extend .align-items-center;
    @extend .py-2;
    @extend .px-3;
    // @extend .small
    display: flex;
    &-icon {
        @extend .mr-2;
        font-size: $font-size-lg; }
    &-info {
        background: $primary-light;
        border-color: $primary-light; }
    &-success {
        background: lighten($green-light, 45%);
        border-color: lighten($green-light, 45%); }
    &-secondary {
        background: $gray-100;
        color: $gray-400; }
    &-warning {
        background: lighten($yellow-light, 25%);
        border-color: lighten($yellow-light, 25%); } }
